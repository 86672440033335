<div class="flex flex-col">
  @if (floatLabel) {
    <p-floatLabel>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p-floatLabel>
  } @else {
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  }

  @if (control && control.invalid && control.errors && (control.dirty || control.touched)) {
    @for (validate of validates; track $index) {
      @if (control.hasError(validate.error)) {
        <small class="text-red-600 mt-1 text-2">{{ validate.message }}</small>
      }
    }
  }
</div>

<ng-template #content>
  <ng-content select="[input]"></ng-content>
  <label [ngClass]="{ '-order-1 mb-1': !floatLabel }"
    >{{ label }}
    @if (required) {
      <span class="text-red-600">*</span>
    }
  </label>
</ng-template>
